import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// COMPONENTS
import LandingPage from "./components/LandingPage/LandingPage";
// NAVIGATIONBAR
import ChemicalNavigationbar from "./components/Chemical/ChemicalNavigationbar/ChemicalNavigationbar";
import Navigationbar from "./components/Medical/Navigationbar/Navigationbar";

/* ------------------- CHEMICAL ------------------- */
import ChemicalHome from "./components/Chemical/ChemicalHome/ChemicalHome";
import ChemicalNewsPage from "./components/Chemical/ChemicalHome/ChemicalNewsPage/ChemicalNewsPage";
import ChemicalNewsPageWithImages from "./components/Chemical/ChemicalHome/ChemicalNewsPage/ChemicalNewsPageWithImages";
import { chemicalNewsItems } from "./components/Chemical/ChemicalHome/chemicalNewsItems";
//CHEMICAL - ABOUT
import ChemicalAboutStory from "./components/Chemical/ChemicalAbout/ChemicalAboutStory";
import ChemicalAboutCareer from "./components/Chemical/ChemicalAbout/ChemicalAboutCareer";
import ChemicalAboutPartners from "./components/Chemical/ChemicalAbout/ChemicalAboutPartners";
import ChemicalAboutQualityControl from "./components/Chemical/ChemicalAbout/ChemicalAboutQualityControl";
import ChemicalAboutDocs from "./components/Chemical/ChemicalAbout/ChemicalAboutDocs";
import ChemicalAboutTeam from "./components/Chemical/ChemicalAbout/ChemicalAboutTeam";
import ChemicalAboutProjects from "./components/Chemical/ChemicalAbout/ChemicalAboutProjects/ChemicalAboutProjects";
//CHEMICAL - CONSULTATION
import ChemicalConsultation from "./components/Chemical/ChemicalConsultation/ChemicalConsultation";
//CHEMICAL - EXAMINATIONS
// import ChemicalExamViz from "./components/Chemical/ChemicalExaminations/ChemicalExamViz/ChemicalExamViz";
import ChemicalExamElelmiszer from "./components/Chemical/ChemicalExaminations/ChemicalExamElelmiszer/ChemicalExamElelmiszer";
import ChemicalExamLevego from "./components/Chemical/ChemicalExaminations/ChemicalExamLevego/ChemicalExamLevego";
import ChemicalExamSampling from "./components/Chemical/ChemicalExaminations/ChemicalExamSampling/ChemicalExamSampling";
//CHEMICAL - SAMPLE RECIEVE
import ChemicalSampleRecieve from "./components/Chemical/ChemicalSampleRecieve/ChemicalSampleRecieve";
//CHEMICAL - PRICE OFFER
import ChemicalPriceOffer from "./components/Chemical/ChemicalPriceOffer/ChemicalPriceOffer";
//CHEMICAL - CONTACT
import ChemicalContactUs from "./components/Chemical/ChemicalContactUs/ChemicalContactUs";

//CHEMICAL - FOOTER
import ChemicalFooter from "./components/Chemical/ChemicalFooter/ChemicalFooter";

/* ------------------- MEDICAL ------------------- */

// MEDICAL - HOME
import Home from "./components/Medical/Home/Home";
// MEDICAL - HOME - NEWS
import NewsPage from "./components/Medical/Home/NewsPage/NewsPage";
import { newsItems } from "./components/Medical/Home/newsItems";
// MEDICAL - ABOUT
import AboutStory from "./components/Medical/About/AboutStory";
import AboutTeam from "./components/Medical/About/AboutTeam";
import AboutCareer from "./components/Medical/About/AboutCareer";
import AboutPartners from "./components/Medical/About/AboutPartners";
// MEDICAL - CONSULTATION
import Consultation from "./components/Medical/Consultation/Consultation";
// MEDICAL - EXAMINATIONS
import Examinations from "./components/Medical/Examinations/Examinations";
import ExaminationsCompany from "./components/Medical/Examinations/ExaminationsCompany";
import { combinedExams } from "./components/Medical/Examinations/exams/allExamsFinal";
import ExamCategoryMintavetel from "./components/Medical/Examinations/ExamCategories/ExamCategoryPage/ExamCategoryMintavetel";
// MEDICAL - EXAMINATIONS - PACKAGES
import ExamPackageSzures from "./components/Medical/Examinations/ExamPackages/ExamPackageSzures/ExamPackageSzures";
import ExamPackageTerhesseg from "./components/Medical/Examinations/ExamPackages/ExamPackageTerhesseg/ExamPackageTerhesseg";
import ExamPackageMeddoseg from "./components/Medical/Examinations/ExamPackages/ExamPackageMeddoseg/ExamPackageMeddoseg";
import ExamPackageTorch from "./components/Medical/Examinations/ExamPackages/ExamPackageTorch/ExamPackageTorch";
import ExamPackageSTD from "./components/Medical/Examinations/ExamPackages/ExamPackageSTD/ExamPackageSTD";
import ExamPackageMutetElott from "./components/Medical/Examinations/ExamPackages/ExamPackageMutetElott/ExamPackageMutetElott";
// MEDICAL - EXAMINATIONS - CATEGORIES
import ExamCategoryPage from "./components/Medical/Examinations/ExamCategories/ExamCategoryPage/ExamCategoryPage";
// MEDICAL - EXAMINATIONS - CATEGORIES - HERO IMAGES
import heroImgHematologia from "./assets/medical/examinations/categories/hematologia/hematologia_banner_nagy.png";
import heroImgHematologiaSmall from "./assets/medical/examinations/categories/hematologia/hematologia_banner_kicsi.png";
import heroImgVercsoport from "./assets/medical/examinations/categories/vercsoport/vercsoport_banner_nagy.png";
import heroImgVercsoportSmall from "./assets/medical/examinations/categories/vercsoport/vercsoport_banner_kicsi.png";
import heroImgVizelet from "./assets/medical/examinations/categories/vizelet/vizelet_banner_nagy.png";
import heroImgVizeletSmall from "./assets/medical/examinations/categories/vizelet/vizelet_banner_kicsi.png";
import heroImgVeralvadas from "./assets/medical/examinations/categories/veralvadas/veralvadas_banner_nagy.png";
import heroImgVeralvadasSmall from "./assets/medical/examinations/categories/veralvadas/veralvadas_banner_kicsi.png";
import heroImgDiabetesz from "./assets/medical/examinations/categories/diabetesz/diabetesz_banner_nagy.png";
import heroImgDiabeteszSmall from "./assets/medical/examinations/categories/diabetesz/diabetesz_banner_kicsi.png";
import heroImgFolyadekhaztartas from "./assets/medical/examinations/categories/folyadekhaztartas/folyadekhaztartas_banner_nagy.png";
import heroImgFolyadekhaztartasSmall from "./assets/medical/examinations/categories/folyadekhaztartas/folyadekhaztartas_banner_kicsi.png";
import heroImgAnaemia from "./assets/medical/examinations/categories/anaemia/anaemia_banner_nagy.png";
import heroImgAnaemiaSmall from "./assets/medical/examinations/categories/anaemia/anaemia_banner_kicsi.png";
import heroImgVerzsirok from "./assets/medical/examinations/categories/verzsirok/verzsirok_banner_nagy.png";
import heroImgVerzsirokSmall from "./assets/medical/examinations/categories/verzsirok/verzsirok_banner_kicsi.png";
import heroImgMaj from "./assets/medical/examinations/categories/maj/maj_banner_nagy.png";
import heroImgMajSmall from "./assets/medical/examinations/categories/maj/maj_banner_kicsi.png";
import heroImgKardialis from "./assets/medical/examinations/categories/kardialis/kardialis_banner_nagy.png";
import heroImgKardialisSmall from "./assets/medical/examinations/categories/kardialis/kardialis_banner_kicsi.png";
import heroImgHasnyalmirigy from "./assets/medical/examinations/categories/hasnyalmirigy/hasnyalmirigy_banner_nagy.png";
import heroImgHasnyalmirigySmall from "./assets/medical/examinations/categories/hasnyalmirigy/hasnyalmirigy_banner_kicsi.png";
import heroImgFeherje from "./assets/medical/examinations/categories/feherje/feherje_banner_nagy.png";
import heroImgFeherjeSmall from "./assets/medical/examinations/categories/feherje/feherje_banner_kicsi.png";
import heroImgAllergologia from "./assets/medical/examinations/categories/allergologia/allergologia_banner_nagy.png";
import heroImgAllergologiaSmall from "./assets/medical/examinations/categories/allergologia/allergologia_banner_kicsi.png";
import heroImgTumor from "./assets/medical/examinations/categories/tumormarker/tumormarker_banner_nagy.png";
import heroImgTumorSmall from "./assets/medical/examinations/categories/tumormarker/tumormarker_banner_kicsi.png";
import heroImgPajzsmirigy from "./assets/medical/examinations/categories/pajzsmirigy/pajzsmirigy_banner_nagy.png";
import heroImgPajzsmirigySmall from "./assets/medical/examinations/categories/pajzsmirigy/pajzsmirigy_banner_kicsi.png";
import heroImgKorokozok from "./assets/medical/examinations/categories/korokozok/korokozok_banner_nagy.png";
import heroImgKorokozokSmall from "./assets/medical/examinations/categories/korokozok/korokozok_banner_kicsi.png";
import heroImgEgyebHormonok from "./assets/medical/examinations/categories/egyeb_hormon/egyeb_hormon_banner_nagy.png";
import heroImgEgyebHormonokSmall from "./assets/medical/examinations/categories/egyeb_hormon/egyeb_hormon_banner_kicsi.png";
import heroImgEgyebVizsgalatok from "./assets/medical/examinations/categories/egyeb_vizsgalatok/egyeb_vizsgalatok_banner_nagy.png";
import heroImgEgyebVizsgalatokSmall from "./assets/medical/examinations/categories/egyeb_vizsgalatok/egyeb_vizsgalatok_banner_kicsi.png";
// import heroImgHormon from "./assets/medical/examinations/categories/";

// MEDICAL - PRICES
import Prices from "./components/Medical/Prices/Prices";
// MEDICAL - APPOINTMENT
import Appointment from "./components/Medical/Appointment/Appointment";
// MEDICAL - CONTACT
import ContactUs from "./components/Medical/Contact/ContactUs";
// MEDICAL - FOOTER
import Footer from "./components/Medical/Footer/Footer";
// NOT IMPLEMENTED
import NotImplementedPage from "./components/NotImplementedPage/NotImplementedPage";
// IMPRESSUM
import Impressum from "./components/Impressum/Impressum";
import Sitemap from "./components/Sitemap/Sitemap";

function App() {
  const location = useLocation();
  const isLandingpage = location.pathname === "/";
  const isChemicalPage = location.pathname.startsWith("/kemiaianalitika");

  const Wrapper = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      // Scroll to the top of the page when the route changes
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [location.pathname]);

    return children;
  };

  return (
    <>
      {!isLandingpage &&
        (isChemicalPage ? <ChemicalNavigationbar /> : <Navigationbar />)}
      <Wrapper>
        <Routes>
          {/*  ---------------------- LANDING RÉSZLEG ---------------------- */}
          <Route path="/" element={<LandingPage />} />
          {
            displayChemicalRoutes() /* Call the function to render Chemical routes */
          }
          {/* ---------------------- ORVOSDIAGNOSZTIKA ---------------------- */}
          {/*  --- FŐOLDAL RÉSZLEG--- */}
          <Route path="/orvosdiagnosztika" element={<Home />} />
          <Route
            path="/orvosdiagnosztika/hirek/megujultunk-elkoltoztunk"
            element={
              <NewsPage
                pageTitle={newsItems[0].title}
                content={newsItems[0].content}
                date={newsItems[0].date}
                heroImage={newsItems[0].heroImg}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/hirek/parkolas-a-labor-elott"
            element={
              <NewsPage
                pageTitle={newsItems[1].title}
                content={newsItems[1].content}
                date={newsItems[1].date}
                heroImage={newsItems[1].heroImg}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/hirek/otthoni-vervetel"
            element={
              <NewsPage
                pageTitle={newsItems[2].title}
                content={newsItems[2].content}
                date={newsItems[2].date}
                heroImage={newsItems[2].heroImg}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/hirek/ev_vegi_nyitvatartas_2024"
            element={
              <NewsPage
                pageTitle={newsItems[3].title}
                content={newsItems[3].content}
                date={newsItems[3].date}
                heroImage={newsItems[3].heroImg}
                images={newsItems[3].images}
                hideCTA={true}
              />
            }
          />
          {/*  --- RÓLUNK RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/rolunk/tortenetunk"
            element={<AboutStory />}
          />
          <Route
            path="/orvosdiagnosztika/rolunk/csapatunk"
            element={<AboutTeam />}
          />
          <Route
            path="/orvosdiagnosztika/rolunk/partnereink"
            element={<AboutPartners />}
          />
          <Route
            path="/orvosdiagnosztika/rolunk/karrier"
            element={<AboutCareer />}
          />
          {/*  --- KONZULTÁCIÓ RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/konzultacio"
            element={<Consultation />}
          />
          {/*  --- VIZSGÁLATOK RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/vizsgalatok"
            element={<Examinations />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/cegeknek"
            element={<ExaminationsCompany />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/mintavetel"
            element={<ExamCategoryMintavetel />}
          />
          {/*  --- VIZSGÁLATOK - CSOMAGOK RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/szures"
            element={<ExamPackageSzures />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/terhesseg"
            element={<ExamPackageTerhesseg />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/meddoseg"
            element={<ExamPackageMeddoseg />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/torch"
            element={<ExamPackageTorch />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/nemikorokozokkimutatasa"
            element={<ExamPackageSTD />}
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/csomagok/mutetelott"
            element={<ExamPackageMutetElott />}
          />
          {/*  --- VIZSGÁLATOK - KATEGÓRIÁK RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/vervetel"
            element={
              <ExamCategoryPage
                pageTitle="Vérvétel"
                categoryType="mintavétel"
                exams={combinedExams}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/otthoni_mintavetel"
            element={
              <ExamCategoryPage
                pageTitle="Otthoni Vérvétel"
                categoryType="otthoni_mintavétel"
                exams={combinedExams}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/hematologia"
            element={
              <ExamCategoryPage
                pageTitle="Hematológia"
                categoryType="hematológia"
                exams={combinedExams}
                heroImage={heroImgHematologia}
                heroImageSmall={heroImgHematologiaSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/vercsoport"
            element={
              <ExamCategoryPage
                pageTitle="Vércsoport"
                categoryType="vércsoport"
                exams={combinedExams}
                heroImage={heroImgVercsoport}
                heroImageSmall={heroImgVercsoportSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/vizelet"
            element={
              <ExamCategoryPage
                pageTitle="Vizelet"
                categoryType="vizelet"
                exams={combinedExams}
                heroImage={heroImgVizelet}
                heroImageSmall={heroImgVizeletSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/veralvadas"
            element={
              <ExamCategoryPage
                pageTitle="Véralvadás"
                categoryType="véralvadás"
                exams={combinedExams}
                heroImage={heroImgVeralvadas}
                heroImageSmall={heroImgVeralvadasSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/diabetesz"
            element={
              <ExamCategoryPage
                pageTitle="Diabétesz"
                categoryType="diabétesz"
                exams={combinedExams}
                heroImage={heroImgDiabetesz}
                heroImageSmall={heroImgDiabeteszSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/folyadekhaztartas"
            element={
              <ExamCategoryPage
                pageTitle="Folyadékháztartás"
                categoryType="folyadékháztartás"
                exams={combinedExams}
                heroImage={heroImgFolyadekhaztartas}
                heroImageSmall={heroImgFolyadekhaztartasSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/anaemia"
            element={
              <ExamCategoryPage
                pageTitle="Anaémia"
                categoryType="anaémia"
                exams={combinedExams}
                heroImage={heroImgAnaemia}
                heroImageSmall={heroImgAnaemiaSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/verzsirok"
            element={
              <ExamCategoryPage
                pageTitle="Vérzsírok"
                categoryType="vérzsírok"
                exams={combinedExams}
                heroImage={heroImgVerzsirok}
                heroImageSmall={heroImgVerzsirokSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/maj"
            element={
              <ExamCategoryPage
                pageTitle="Máj"
                categoryType="máj"
                exams={combinedExams}
                heroImage={heroImgMaj}
                heroImageSmall={heroImgMajSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/kardialis"
            element={
              <ExamCategoryPage
                pageTitle="Kardiális"
                categoryType="kardiális"
                exams={combinedExams}
                heroImage={heroImgKardialis}
                heroImageSmall={heroImgKardialisSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/hasnyalmirigy"
            element={
              <ExamCategoryPage
                pageTitle="Hasnyálmirigy"
                categoryType="hasnyálmirigy"
                exams={combinedExams}
                heroImage={heroImgHasnyalmirigy}
                heroImageSmall={heroImgHasnyalmirigySmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/feherje"
            element={
              <ExamCategoryPage
                pageTitle="Fehérje"
                categoryType="fehérje vizsgálatok"
                exams={combinedExams}
                heroImage={heroImgFeherje}
                heroImageSmall={heroImgFeherjeSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/allergologia"
            element={
              <ExamCategoryPage
                pageTitle="Allergológia"
                categoryType="allergológia"
                exams={combinedExams}
                heroImage={heroImgAllergologia}
                heroImageSmall={heroImgAllergologiaSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/tumormarkerek"
            element={
              <ExamCategoryPage
                pageTitle="Tumor markerek"
                categoryType="tumor markerek"
                exams={combinedExams}
                heroImage={heroImgTumor}
                heroImageSmall={heroImgTumorSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/pajzsmirigy"
            element={
              <ExamCategoryPage
                pageTitle="Pajzsmirigy"
                categoryType="pajzsmirigy"
                exams={combinedExams}
                heroImage={heroImgPajzsmirigy}
                heroImageSmall={heroImgPajzsmirigySmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/korokozok"
            element={
              <ExamCategoryPage
                pageTitle="Kórokozók kimutatása"
                categoryType="kórokozók kimutatása"
                exams={combinedExams}
                heroImage={heroImgKorokozok}
                heroImageSmall={heroImgKorokozokSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/egyeb_hormonok"
            element={
              <ExamCategoryPage
                pageTitle="Egyéb hormonok"
                categoryType="egyéb hormonok"
                exams={combinedExams}
                heroImage={heroImgEgyebHormonok}
                heroImageSmall={heroImgEgyebHormonokSmall}
              />
            }
          />
          <Route
            path="/orvosdiagnosztika/vizsgalatok/kategoriak/egyeb_vizsgalatok"
            element={
              <ExamCategoryPage
                pageTitle="Egyéb vizsgálatok"
                categoryType="egyéb vizsgálatok"
                exams={combinedExams}
                heroImage={heroImgEgyebVizsgalatok}
                heroImageSmall={heroImgEgyebVizsgalatokSmall}
              />
            }
          />
          {/*  --- ÁRAK RÉSZLEG--- */}
          <Route path="/orvosdiagnosztika/araink" element={<Prices />} />
          {/*  --- IDŐPONTFOGLALÁS RÉSZLEG--- */}
          <Route
            path="/orvosdiagnosztika/idopontfoglalas"
            element={<Appointment />}
          />
          {/*  --- KAPCSOLAT RÉSZLEG--- */}
          <Route path="/orvosdiagnosztika/kapcsolat" element={<ContactUs />} />
          {/*  --- NOT IMPLEMENTED RÉSZLEG--- */}
          <Route path="/impresszum" element={<Impressum />} />
          <Route path="/oldalterkep" element={<Sitemap />} />
          <Route path="*" element={<NotImplementedPage />} />
        </Routes>
        {!isLandingpage && (isChemicalPage ? <ChemicalFooter /> : <Footer />)}
      </Wrapper>
    </>
  );
}

function displayChemicalRoutes() {
  // Define a boolean variable to toggle all Chemical routes
  const displayChemical = true;

  if (displayChemical) {
    return (
      <>
        {/* Chemical routes */}
        {/* ---------------------- KÉMIAI ANALITIKA ----------------------
        {/*  --- FŐOLDAL RÉSZLEG--- */}
        <Route path="/kemiaianalitika" element={<ChemicalHome />} />
        <Route
          path="/kemiaianalitika/hirek/megujultunk-elkoltoztunk"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[0].title}
              content={chemicalNewsItems[0].content}
              date={chemicalNewsItems[0].date}
              heroImage={chemicalNewsItems[0].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/parkolas-a-labor-elott"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[1].title}
              content={chemicalNewsItems[1].content}
              date={chemicalNewsItems[1].date}
              heroImage={chemicalNewsItems[1].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/mentes-es-funkcionalis-termek-versenye"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[2].title}
              content={chemicalNewsItems[2].content}
              date={chemicalNewsItems[2].date}
              heroImage={chemicalNewsItems[2].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/danube_sediment_balance_projekt"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[3].title}
              content={chemicalNewsItems[3].content}
              date={chemicalNewsItems[3].date}
              heroImage={chemicalNewsItems[3].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/cefood_2024_konferencia"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[4].title}
              content={chemicalNewsItems[4].content}
              date={chemicalNewsItems[4].date}
              heroImage={chemicalNewsItems[4].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/kutatok_ejszakaja_2024"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[5].title}
              content={chemicalNewsItems[5].content}
              date={chemicalNewsItems[5].date}
              heroImage={chemicalNewsItems[5].heroImg}
              images={chemicalNewsItems[5].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/environtec_kiallitas_2024"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[6].title}
              content={chemicalNewsItems[6].content}
              date={chemicalNewsItems[6].date}
              heroImage={chemicalNewsItems[6].heroImg}
              images={chemicalNewsItems[6].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/uj-akkreditalt-statusz"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[7].title}
              content={chemicalNewsItems[7].content}
              date={chemicalNewsItems[7].date}
              heroImage={chemicalNewsItems[7].heroImg}
            />
          }
        />{" "}
        <Route
          path="/kemiaianalitika/hirek/compsa_mernokverseny"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[8].title}
              content={chemicalNewsItems[8].content}
              date={chemicalNewsItems[8].date}
              heroImage={chemicalNewsItems[8].heroImg}
              images={chemicalNewsItems[8].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/unnepi_nyitvatartas_2024"
          element={
            <ChemicalNewsPage
              pageTitle={chemicalNewsItems[9].title}
              content={chemicalNewsItems[9].content}
              date={chemicalNewsItems[9].date}
              heroImage={chemicalNewsItems[9].heroImg}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/orszagos-kornyezetvedelmi-talalkozo"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[10].title}
              content={chemicalNewsItems[10].content}
              date={chemicalNewsItems[10].date}
              heroImage={chemicalNewsItems[10].heroImg}
              images={chemicalNewsItems[10].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/husipari_szakmai_nap"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[11].title}
              content={chemicalNewsItems[11].content}
              date={chemicalNewsItems[11].date}
              heroImage={chemicalNewsItems[11].heroImg}
              images={chemicalNewsItems[11].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/nonap_balintanalitika"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[12].title}
              content={chemicalNewsItems[12].content}
              date={chemicalNewsItems[12].date}
              heroImage={chemicalNewsItems[12].heroImg}
              images={chemicalNewsItems[12].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/szakma_sztar_fesztival"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[13].title}
              content={chemicalNewsItems[13].content}
              date={chemicalNewsItems[13].date}
              heroImage={chemicalNewsItems[13].heroImg}
              images={chemicalNewsItems[13].images}
            />
          }
        />
        <Route
          path="/kemiaianalitika/hirek/mentes_feszt_2025"
          element={
            <ChemicalNewsPageWithImages
              pageTitle={chemicalNewsItems[14].title}
              content={chemicalNewsItems[14].content}
              date={chemicalNewsItems[14].date}
              heroImage={chemicalNewsItems[14].heroImg}
              images={chemicalNewsItems[14].images}
            />
          }
        />
        {/*  --- RÓLUNK RÉSZLEG--- */}
        <Route
          path="/kemiaianalitika/rolunk/tortenetunk"
          element={<ChemicalAboutStory />}
        />
        <Route
          path="/kemiaianalitika/rolunk/csapatunk"
          element={<ChemicalAboutTeam />}
        />
        <Route
          path="/kemiaianalitika/rolunk/partnereink"
          element={<ChemicalAboutPartners />}
        />
        <Route
          path="/kemiaianalitika/karrier"
          element={<ChemicalAboutCareer />}
        />
        <Route
          path="/kemiaianalitika/rolunk/minosegiranyitas"
          element={<ChemicalAboutQualityControl />}
        />
        <Route
          path="/kemiaianalitika/rolunk/dokumentumok"
          element={<ChemicalAboutDocs />}
        />
        <Route
          path="/kemiaianalitika/rolunk/projektek"
          element={<ChemicalAboutProjects />}
        />
        {/*  --- KONZULTÁCIÓ RÉSZLEG--- */}
        <Route
          path="/kemiaianalitika/szaktanacsadas"
          element={<ChemicalConsultation />}
        />
        {/*  --- VIZSGÁLATOK RÉSZLEG--- */}
        {/* <Route
          path="/kemiaianalitika/vizsgalatok/viz"
          element={<ChemicalExamViz />}
        /> */}
        <Route
          path="/kemiaianalitika/vizsgalatok/elelmiszer"
          element={<ChemicalExamElelmiszer />}
        />
        <Route
          path="/kemiaianalitika/vizsgalatok/levego"
          element={<ChemicalExamLevego />}
        />
        {/*  ----- CHEMICAL - EXAM SAMPLING PAGES */}
        <Route
          path="/kemiaianalitika/vizsgalatok/mintavetel"
          element={<ChemicalExamSampling />}
        />
        {/* ----- CHEMICAL - SAMPLE RECIEVE ------ */}
        <Route
          path="/kemiaianalitika/mintafogadas"
          element={<ChemicalSampleRecieve />}
        />
        {/* ----- CHEMICAL - PRICE OFFER ------ */}
        <Route
          path="/kemiaianalitika/arajanlatkeres"
          element={<ChemicalPriceOffer />}
        />
        {/*  --- KAPCSOLAT RÉSZLEG--- */}
        <Route
          path="/kemiaianalitika/kapcsolat"
          element={<ChemicalContactUs />}
        />
        {/* Always render the "Not Implemented" page for any undefined Chemical route */}
        <Route path="*" element={<NotImplementedPage />} />
      </>
    );
  }
  // Return an empty fragment if the Chemical routes are not displayed
  return <></>;
}
export default App;
