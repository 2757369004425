export const chemicalNewsItems = [
  {
    id: 1,
    title: "Megújultunk, elköltöztünk!",
    contentLink: "/kemiaianalitika/hirek/megujultunk-elkoltoztunk",
    picLink: "/assets/medical/home/news/news/megujultunk_news_card.png",
    heroImg: "/assets/medical/home/news/news/megujultunk_banner_nagy.png",
    date: "2023.10.10.",
    content: `A Bálint Analitika Kémiai Laboratóriuma új telephelyen (1116 Budapest, Kondorfa utca 6.) folytatja tevékenységét.`,
  },
  {
    id: 2,
    title: "Parkolás a Kémiai laborunk előtt a belső udvarban",
    contentLink: "/kemiaianalitika/hirek/parkolas-a-labor-elott",
    picLink: "/assets/medical/home/news/news/parkolas_news_card.png",
    heroImg: "/assets/medical/home/news/news/parkolas_banner_nagy.png",
    date: "2023.10.10.",
    content: `Amennyiben a laboratóriumunkhoz autóval érkezne, kérjük hogy ne a Google Térkép alapján próbáljon bejönni, mert az sajnos még nem pontos, ennek javítása még folyamatban van. Az itt található térkép alapján könnyen megtalálható a mintavételi helyszín. Köszönjük a megértésüket!
    <img src=/assets/chemical/contactus/kemiai_analitika_map.png alt="Parkolás a kémiai labor előtt a belső udvarban" />`,
  },
  {
    id: 3,
    title: `A Bálint Analitika Kft. adott otthont a 2023. évi "Mentes és funkcionális termékek" versenyének.`,
    contentLink:
      "/kemiaianalitika/hirek/mentes-es-funkcionalis-termek-versenye",
    picLink: "/assets/chemical/home/news/dijatado_card.png",
    heroImg: "/assets/chemical/home/news/dijatado_banner_nagy.png",
    date: "2023.12.05.",
    content: `Megtiszteltetés számunkra, hogy 2023 tavaszán a PPH Media munkatársai felkértek bennünket a Minőségi mentes és funkcionális termékek versenyének lebonyolításában való részvételre. A felkérésnek örömmel tettünk eleget, így a 2023. október 13-án a versenyre benevezett, több mint 150 termék érzékszervi bírálatát a közelmúltban felújított laboratóriumi épületünk hangulatos éttermében végeztük a PPH Media által felkért zsűritagok közreműködésével. A zsűriben a Bálint Analitika Kft-t Dr. Márta Zoltán ügyvezető igazgató és zsűri-elnökként Dr. Szigeti Tamás János stratégiai igazgató képviselte.
    <br /><br />
    Reméljük, hogy az élelmiszergyártás területén az illetékes szakemberek továbbra is kellő figyelmet fordítanak a különleges táplálkozási igénnyel, allergiával, étel-intoleranciával élő fogyasztók igényeire, ami sajnos gyakran életbiztonsági szükséglet is. A Bálint Analitika független, akkreditált laboratóriumi szolgáltatásaival, munkatársainak szakértelmével e célnak a megvalósítása érdekében a jövőben is készséggel vesz részt ilyen, és hasonló feladatok megoldásában.
    <br /><br />
    Végezetül, örömmel gratulálunk a 2023-as <strong>Mentes verseny</strong> díjazottjainak. Valamennyi részvevőnek pedig további innovatív, sikeres munkát, az év közeledtével meghitt karácsonyt és boldog Újévet kívánunk.
    <br /><br />
    A verseny díjátadó ünnepségéről készült összefoglaló és a rendezvényen készült képgaléria következő URL-címen tekinthető meg:
    <br /><br />
    <a href="https://storeinsider.hu/cikk/mentes-es-funkcionalis-termekek-versenye-2023-ime-a-nyertesek" target="_blank">https://storeinsider.hu/cikk/mentes-es-funkcionalis-termekek-versenye-2023-ime-a-nyertesek</a> 
    <br /><br />
     
    <strong>Dr. Márta Zoltán</strong> <br />
    Ügyvezető igazgató<br /><br />
    és<br /><br />
    <strong>Dr. Szigeti Tamás János</strong><br />
    Stratégiai igazgató`,
  },
  {
    id: 4,
    title: "Danube Sediment Balance projekt részvétel",
    contentLink: "/kemiaianalitika/hirek/danube_sediment_balance_projekt",
    picLink: "/assets/chemical/home/news/danube_sediment_card.webp",
    heroImg: "/assets/chemical/home/news/danube_sediment_banner_nagy.webp",
    date: "2024.07.15.",
    content: `
    Örömmel jelentjük be, hogy részt veszünk a Danube Sediment Balance projektben (azonosító: DanubeSediment_Q2 DRP0200029), amely a Duna vízgyűjtőjén javítja a hordalék mennyiségét és minőségét.
    Nagy megtiszteltetés számunkra, hogy részesei lehetünk ennek a jelentős kezdeményezésnek.
    <br /><br />
    A projekt célja egy integrált hordalékkezelési terv kidolgozása, amelyet a Nemzetközi Duna Védelmi Bizottság is figyelembe vesz majd.
    <br />
    A fejlesztéshez új monitoring hálózatot és értékelési módszereket alkalmazunk, az érdekelt felek bevonásával.
    <br /><br />
    A projektben való részvételünktől bővebben <a href="https://balintanalitika.hu/kemiaianalitika/rolunk/projektek">itt</a> olvashatnak.<br />
    További információkért, legfrissebb pedig a projekt saját oldalán, <a href="https://interreg-danube.eu/projects/danubesediment-q2" target="_blank" rel="noreferrer">ezen a linken</a> olvashatnak angol nyelven.
    `,
  },
  {
    id: 5,
    title: "CEFood 2024 Konferencia",
    contentLink: "/kemiaianalitika/hirek/cefood_2024_konferencia",
    picLink: "/assets/chemical/home/news/cefood_conference_card.webp",
    heroImg: "/assets/chemical/home/news/cefood_conference_banner_nagy.webp",
    date: "2024.10.29.",
    content: `
    A kétévente megrendezett CEFood (Central European Food) tudományos konferenciát 2024. október 13 és 16. között az idei esztendőben Szegeden rendezték meg a Magyar Táplálkozástudományi Társaság (MTTT) és a Diamond Congress Konferenciaszervező iroda szervezésében. A 4 napos konferencián hat plenáris, 20 normál, valamint 12 "flesh" prezentáció hangzott el. Ezen felül 37 poszter szóbeli vitájára is sor került. A konferencián a Bálint Analitika Kft. is érdekelt volt. Az élelmiszerbiztonsággal kapcsolatos témakörök között ugyanis, stratégiai igazgatónk, Dr. Szigeti Tamás János tartott plenáris előadást. A prezentáció társ-szerzője szintén Laboratóriumunk munkatársa, Czigány Máté volt. Prezentációjuk címe: "Mineral oil origin pollutants (MOSH/MOAH) in our environment and foods". 
    <br /><br />
    A konferencia honlapja az alábbi címen érhető el: <a href="https://cefood2024.hu/" target="_blank" rel="noreferrer">https://cefood2024.hu</a>.
    <br />
    A plenáris előadók rövid életrajza, illetve a konferencia teljes programjának előadás kivonatairól bővebben: <a href="https://cefood2024.hu/programme" target="_blank" rel="noreferrer">https://cefood2024.hu/programme</a>.
     <br /><br />
    Az előadás aktualitását az adja, hogy a Bálint Analitika Kft. Laboratóriuma a Nemzeti Akkreditáló Hatóság által ellenőrzött módszerrel rendszeresen végzi az élelmiszerlánc-biztonságot veszélyeztető szennyezőanyagok között a kőolajokból származó telített és aromás vegyületek (MOSH/MOAH) minőségi és mennyiségi meghatározását. A MOSH/MOAH vegyületek káros élettani hatásairól a következő oldalon olvashatnak: <a href="https://www.efsa.europa.eu/en/efsajournal/pub/8215" target="_blank" rel="noreferrer">itt</a>.`,
  },

  {
    id: 6,
    title: "A Bálint Analitika Kft. részt vett a Kutatók Éjszakáján",
    contentLink: "/kemiaianalitika/hirek/kutatok_ejszakaja_2024",
    picLink: "/assets/chemical/home/news/kutatok_ejszakaja_card.webp",
    heroImg: "/assets/chemical/home/news/kutatok_ejszakaja_banner_nagy.webp",
    date: "2024.11.03.",
    content: `
    A Bálint Analitika kft rendszeres résztvevője a kutatók éjszakájának, Az idei rendezvény keretein belül – a Környezetvédelmi Gyártók és Szolgáltatók Szövetsége – KSZGYSZ - tagjaként a Bay Zoltán Intézet Kondorfa utcai telephelyén állítottuk fel sátrunkat, ahol ezúttal Laboratóriumunk levegőmintavételi tevékenységéből mutattunk be néhányat. A kiállítással egybekötött interaktív program során PM10, PM5 és PM2,5 (10, 5, illetve 2,5 µm átmérőjű porszemcsék) pormitázásra szolgáló, immisszió-vizsgáló automata mintavevő berendezéseink, illetve pontszerű emissziós források ellenőrzésére alkalmas, intelligens (izokinetikus) mintázó berendezéseink közöl mutattunk be egyet-egyet. A standunkat meglátogató vendégek nagy érdeklődést mutattak, különösen az egyes települések forgalmas terein felállított mérőberendezéseink által összegyűjtött porminták egészségügyi hatásait illetően. Szervezetünket Dr. Márta Zoltán ügyvezető igazgató és Dr. Szigeti Tamás János stratégiai igazgató képviselte a rendezvényen.
    `,
    images: [
      {
        url: "/assets/chemical/home/news/kutatok_gallery_2024/kutatok1.jpg",
        caption:
          "Tóth Viktória (SciAps műszerek) és Dr. Szigeti Tamás János (Bálint Analitika Kft.)",
      },
      {
        url: "/assets/chemical/home/news/kutatok_gallery_2024/kutatok2.jpg",
        caption:
          "Dr. Jeney Judit ügyvezető és Tóth Viktória (SciAps műszerek) valamint Dr. Márta Zoltán és Szigeti Tamás János (Bálint Analitika Kft.)",
      },
      {
        url: "/assets/chemical/home/news/kutatok_gallery_2024/kutatok3.jpg",
        caption:
          "Dr. Márta Zoltán magyarázza az immissziós prmintázás jelentőségét az érdeklődőknek (Bálint Analitika Kft.)",
      },
      {
        url: "/assets/chemical/home/news/kutatok_gallery_2024/kutatok4.jpg",
        caption: "Leszállt az éj… A KSZGYSZ sátras stand-pavilonjai",
      },
      {
        url: "/assets/chemical/home/news/kutatok_gallery_2024/kutatok5.jpg",
        caption:
          "A Bálint Analitika Kft. Által bemutatott izokinetikus emmisszió-mérő berendezés, a kis szűrőkben az automata immisszió-mérő berendezés által összegyűjtött porminták láthatók.",
      },
    ],
  },
  {
    id: 7,
    title: "ENVIRONTEC kiállításon voltunk!",
    contentLink: "/kemiaianalitika/hirek/environtec_kiallitas_2024",
    picLink: "/assets/chemical/home/news/environtec_kiallitas_card.webp",
    heroImg: "/assets/chemical/home/news/environtec_kiallitas_banner_nagy.webp",
    date: "2024.11.05.",
    content: `
    A hosszú évek alatt rendszeressé váló, a környezetvédelemről és a fenntarthatóságról szóló, a Környezetvédelmi Gyártók és Szolgáltatók Szövetsége által koordinált  Ökoindustria kiállítás és expo 2024-ben az ENVIRONTEC kiállítást és konferencia-sorozatokat magába foglaló, a HUNGEXPO területén szervezett rendezvénnyé alakult.  Az expón régi hagyomány szerint a Bálint Analitika Kft. is standdal fogadta a környezetvédelem technológiája, tudományos háttere iránt érdeklődő látogatókat. A három nap alatt standunkat közel 120 szakember és laikus látogatta meg, és folytatott hasznos szakmai, illetve ismeretterjesztő célú megbeszéléseket. Laboratóriumunkat a kiállításon Bálint Mária és Dr. Márta Zoltán ügyvezetők, valamint Dr. Szigeti Tamás János stratégiai igazgató képviselte. 
    `,
    images: [
      {
        url: "/assets/chemical/home/news/environtec_gallery/environtec_1.jpg",
        caption:
          "A Bálint Analitika Kft. Standja az expo megnyitása előtti estén",
      },
      {
        url: "/assets/chemical/home/news/environtec_gallery/environtec_2.jpg",
        caption:
          "Bálint Mária, Dr. Márta Zoltán ügyvezetők és a PPH Media Kft. munkatársai a Bálint Analitika Kft. standjánál",
      },
      {
        url: "/assets/chemical/home/news/environtec_gallery/environtec_3.jpg",
        caption:
          "Bálint Mária, Dr. Szigeti Tamás János és a PPH Media Kft. munkatársai a Bálint Analitika Kft. standjánál",
      },
      {
        url: "/assets/chemical/home/news/environtec_gallery/environtec_4.jpg",
        caption:
          "A környezetvédelem és fenntarthatóság témái iránt érdeklődő diákok a Debreceni Egyetemről Dr. Szigeti Tamás János stratégiai igazgató Bálint Analitika Kft. standjánál",
      },
      {
        url: "/assets/chemical/home/news/environtec_gallery/environtec_5.jpg",
        caption:
          "Hankó Gergely a KSZGSZ ügyvezető igazgatója a KSZGYSZ-nek adományozó elismerő oklevelet tartja a kezében. A képen Bálint Mária ügyvezető igazgató (Bálint Analitika Kft.) Králl Ildikó irodavezető és Kovács András ügyvezető igazgató (IMSYS Kft.)",
      },
      {
        url: "/assets/chemical/home/news/environtec_gallery/environtec_6.jpg",
        caption:
          "A fotó az ENVIRONTEC záró napján készült, s a kiállítás és expo koordinátora, a KSZGYSZ  és a Bálint Analitika Kft. munkatársai  láthatóak rajta. Középen, Markó Csaba nyugalmazott szakmai igazgató tartja kezében a KSZGYSZ-nek adományozott elismerő oklevelet",
      },
      {
        url: "/assets/chemical/home/news/environtec_gallery/environtec_7.jpg",
        caption:
          "A fotó az ENVIRONTEC záró napján készült, s a kiállítás és expo koordinátora, a KSZGYSZ  és a Bálint Analitika Kft. munkatársai  láthatóak rajta",
      },
    ],
  },
  {
    id: 8,
    title: "Megkaptuk újabb, 5 éves akkreditált státuszunkat",
    contentLink: "/kemiaianalitika/hirek/uj-akkreditalt-statusz",
    picLink: "/assets/chemical/home/news/akkreditacio_card.webp",
    heroImg: "/assets/chemical/home/news/akkreditacio_banner_nagy.webp",
    date: "2024.11.08.",
    content: `
    Kedves Ügyfeleink!
    <br /><br />
    Örömmel tájékoztatjuk Önöket, hogy 2024. november 7-i induló hatállyal ismét megkaptuk, 5 évre szóló akkreditált státuszunkat a Nemzeti Akkreditáló Hatóságtól. Az új akkreditálási oklevél kiadásával új, NAH nyilvántartási számot is kaptunk: <strong>NAH-1-1666/2024</strong>.
    <br /><br />
    Eddigi bizalmukat megköszönve bízunk benne, hogy új akkreditálási státuszunk birtokában továbbra segíthetjük az Önök munkáját Laboratóriumunk vizsgáló tevékenységével.
    <br /><br />
    Az új akkreditálási okiratunkat és az érvényes határozatot a <a href="/kemiaianalitika/rolunk/dokumentumok">Dokumentumok</a> menüpontban találják.
    `,
  },
  {
    id: 9,
    title: "CompSa mérnökverseny a fenntartható vertikális mezőgazdaságért",
    contentLink: "/kemiaianalitika/hirek/compsa_mernokverseny",
    picLink: "/assets/chemical/home/news/compsa_card.webp",
    heroImg: "/assets/chemical/home/news/compsa_banner_nagy.webp",
    date: "2024.11.28.",
    content: `
      A CompSa verseny zsűrijében a Bálint Analitika is képviselteti magát.
      <br /><br />
      A Siemens Cégcsoportba tartozó Evosoft Hungary Kft (<a href="https://www.evosoft.hu/" target="_blank" rel="noreferrer">https://www.evosoft.hu/</a>) szervezésében és szponzorálásval, a BEST Budapest közreműködésével idén 2024. november 28-29-én tartják a nemzetközi CompSa (Competition of Sustainable Agriculture - <a href="https://mernokverseny.hu/about.html " target="_blank" rel="noreferrer">https://mernokverseny.hu/about.html</a>) elnevezésű elméleti szakmai versenyt, ahol a Magyarországon tanuló magyar és külföldi fiatalok mérhetik össze innovatív tudásukat a fenntartható mezőgazdasági alapokon működő élelmiszertermelés témakörében. A fiataloknak 2-4 fős csoportokban kétnapos versenyen kell számot adniuk arról, hogy a mai napon kapott feladatot - a vertikális agrártermelés - területén olyan agrotechnikai és élelmiszer-feldolgozási technológiákat tervezzenek, amelyek úgy ökológiai, mint ökonómiai szemszögből támogatják a fenntarthatóságot, ugyanakkor elméletileg akár a gyakorlatban is megvalósítható lehet.
      <br /><br />
      Megtiszteltetés számunkra, hogy a rendezők e neves versengés zsűritagjai közé a Bálint Analitika Kft.-től is kértek jelölteket. Ennek okán a Bálint Analitika Kft.-t Dr. Szigeti Tamás János stratégiai igazgató képviseli.
      <br /><br />
      A nyolc fős zsűri tagjai ABC sorrendben a következők:
      <br />
      <ul>
        <li>Bíró Tibor Dr. (Nemzeti Közszolgálati Egyetem)</li>
        <li>Gál Dávid (Evosoft)</li>
        <li>Heindl, Therese (Project manager Zurich Drainage System)</li>
        <li>Korányi László (Nemzeti Innovációs Ügynökség)</li>
        <li>Majoros Attila (Evosoft)</li>
        <li>Sárdi Péter (Evosoft)</li>
        <li>Szigeti Tamás János Dr. (Bálint Analitika Kft.)</li>
        <li>Vizlák Milán (Evosoft)</li>
      </ul>
      <br />
      A verseny eredményhirdetésére 2024. november 29-én, a késő délutáni órákban fog sor kerülni. A jó helyezéseket elérő csapatok tagjai magyarországi tanulásukhoz kapnak pénzügyi támogatást, ezen felül pedig szakmai támogatásban részesülhetnek, ha a mostani kompetició témájában érdekelt cégekhez szeretnének elhelyezkedni tanulmányaik befejezése után.
    `,
    images: [
      {
        url: "/assets/chemical/home/news/compsa_gallery/compsa1.webp",
        caption: "",
      },
      {
        url: "/assets/chemical/home/news/compsa_gallery/compsa2.webp",
        caption: "",
      },
      {
        url: "/assets/chemical/home/news/compsa_gallery/compsa3.webp",
        caption: "",
      },
      {
        url: "/assets/chemical/home/news/compsa_gallery/compsa4.webp",
        caption: "",
      },
    ],
  },
  {
    id: 10,
    title: "A Bálint Analitika év végi nyitva tartása",
    contentLink: "/kemiaianalitika/hirek/unnepi_nyitvatartas_2024",
    picLink: "/assets/chemical/home/news/unnepi_news_card.webp",
    heroImg: "/assets/chemical/home/news/unnepi_banner_nagy.webp",
    date: "2024.12.04.",
    content: `
    Tájékoztatjuk kedves ügyfeleinket, hogy a Laboratóriumunkban 2024. december 12-ével bezárólag érkező minták vizsgálatát tudja még 2024. évi teljesítéssel vállalni. 
    <br /><br />
    2024. december 7-én és 14-én mintaátvételi részlegünk 14 óráig ügyeletet tart, így a meglévő minták leadására van lehetőség.
    <br /><br />
    Mintafogadásra 2024. december 20-ig van lehetőségünk. A december 13-20. között beérkező minták vizsgálati eredményeinek kiadásával kapcsolatban kérjük, szíveskedjenek kapcsolattartóinkat, telefonon megkeresni. Laboratóriumunk 2024. december 21. és 2025. január 1. között zárva tart.<br />
    Nyitvatartásunkkal, szolgáltatásainkkal kapcsolatos kérdéseikkel bátran keressenek meg minket. 
    <br /><br />
    Minden kedves ügyfelünknek, kollégánknak áldott karácsonyi ünnepet, boldog, sikeres újesztendőt kívánunk.
    `,
  },
  {
    id: 11,
    title: "24. Országos Környezetvédelmi Találkozó és Díjátadó Gála (2025)",
    contentLink: "/kemiaianalitika/hirek/orszagos-kornyezetvedelmi-talalkozo",
    picLink: "/assets/chemical/home/news/kornyezetvedelmi_dijatado_card.webp",
    heroImg:
      "/assets/chemical/home/news/kornyezetvedelmi_dijatado_banner_nagy.webp",
    date: "2025.03.07.",
    content: `
    A Környezetvédelmi Gyártók és Szolgáltatók Szövetsége (KSZGYSZ) 2025. március 1-jén tartotta szokásos, évente esedékes, sorrendben a 24. Országos Környezetvédelmi Találkozó és Díjátadó Gála (2025) rendezvényét. A díjátadón a KSZGYSZ Elnökségének szavazatai alapján a környezetvédelem területén kimagasló eredményeket elérő vállalkozásokat, és szakembereket részesítették a "Környezet Védelméért 2024" díjban. A rendezvény részleteit a Trade magazin oldalán az erről szóló cikk linkjén tekinthetik meg <a href="https://trademagazin.hu/hu/a-kszgysz-dijazta-a-hazai-kornyezetipar-teruleten-kiemelkedo-eredmenyeket-felmutato-cegeket-es-szemelyeket" target="_blank" rel="noreferrer">ITT</a>. 
    <br /><br />
    Az eseményen a Bálint Analitika Kft.-t Bálint Mária ügyvezető, Dr. Márta Zoltán ügyvezető, Palik Dénesné ügyvezető igazgató-helyettes és Dr. Szigeti Tamás János stratégiai igazgató képviselte. A bálint Analitika értékes tombola-ajándékokkal járult hozzá a szakmai est sikeréhez. A tombola-ajándékokat Dr. Márta Zoltán ügyvezető igazgató adta át a szerencsés nyerteseknek.
    `,
    images: [
      {
        url: "/assets/chemical/home/news/orszagos-kornyezetvedelmi-talalkozo/kszgysz_1.webp",
      },
      {
        url: "/assets/chemical/home/news/orszagos-kornyezetvedelmi-talalkozo/kszgysz_2.webp",
      },
      {
        url: "/assets/chemical/home/news/orszagos-kornyezetvedelmi-talalkozo/kszgysz_3.webp",
      },
    ],
  },
  {
    id: 12,
    title:
      "Húsipari Szakmai Nap a MATE Budai Campusán 2025. március 4-én, Húshagyókedden",
    contentLink: "/kemiaianalitika/hirek/husipari_szakmai_nap",
    picLink: "/assets/chemical/home/news/husipari_card.webp",
    heroImg: "/assets/chemical/home/news/husipari_banner_nagy.webp",
    date: "2025.03.07.",
    content: `
    A Magyar Agrár- és Élettudományi Egyetem (MATE) Budai Campusán tartották meg a húsipar aktuális kérdéseit és jövőbeli trendjeit taglaló Húsipari Szakmai napot, 2025. március 4-én. A rendezvényen Tarpataki Tamás, az Agrárminisztérium agrárpiacért felelős helyettes államtitkára, valamint Boromisza Zsombor, a MATE Budai Campusának főigazgató-helyettese köszöntötte a résztvevőket.
    <br /><br />
    A konferencián húsiparban érdekelt szervezetek (gyártók, forgalmazók, oktatási intézmények és hatóságok) szakemberei tartottak előadásokat. A konferenciáról szóló részletes beszámolót <a href="https://budaicampus.uni-mate.hu/h%C3%ADr/-/content-viewer/sikeresen-lezajlott-a-h%C3%BAsipari-szakmai-nap-a-mate-budai-campus%C3%A1n/54944" target="_blank" rel="noreferrer">ITT</a> tekinthetik meg.
    <br /><br />
    A rendezvényen a Bálint Analitika Kft. kiállítóként képviseltette magát Nagy Mariann Élelmiszer osztályvezető és Dr. Szigeti Tamás János stratégiai igazgató személyében. A Bálint Analitika standján értékes beszélgetéseket folytattunk a laboratóriumi vizsgálatok után érdeklődő kollégákkal.
    `,
    images: [
      {
        url: "/assets/chemical/home/news/husipari/husipari_1.webp",
      },
      {
        url: "/assets/chemical/home/news/husipari/husipari_2.webp",
      },
      {
        url: "/assets/chemical/home/news/husipari/husipari_3.webp",
      },
      {
        url: "/assets/chemical/home/news/husipari/husipari_4.webp",
      },
    ],
  },
  {
    id: 13,
    title: "Nőnapi köszöntő",
    contentLink: "/kemiaianalitika/hirek/nonap_balintanalitika",
    picLink: "/assets/chemical/home/news/nonap_2025_card.webp",
    heroImg: "/assets/chemical/home/news/nonap_2025_banner_nagy.webp",
    date: "2025.03.08.",
    content: `
    A Bálint Analitika Kft. nevében szívből köszöntünk minden hölgyet nemzetközi nőnap alkalmából!
    <br /><br />
    Ez a nap arra emlékeztet bennünket, hogy elismerjük és megbecsüljük mindazt a kitartást, elhivatottságot és értéket, amelyet a nők a mindennapokban képviselnek - legyen szó munkáról, családról vagy a közösség építéséről.
    <br /><br />
    Köszönjük a munkájukat, odaadásukat és inspiráló jelenlétüket!
    <br /><br />
    Boldog nőnapot kívánunk!`,
  },
  {
    id: 14,
    title: "Szakma Sztár Fesztivál, 2025 - Bálint Analitika Kft.",
    contentLink: "/kemiaianalitika/hirek/szakma_sztar_fesztival",
    picLink: "/assets/chemical/home/news/szakma_sztar_card.webp",
    heroImg: "/assets/chemical/home/news/szakma_sztar_banner_nagy.webp",
    date: "2025.03.09.",
    content: `
    Immár hagyománynak tekinthető, hogy a Bálint Analitika Kft. idén is otthont adott a Magyar Kereskedelmi és Iparkamara által szervezett Szakma Sztár Fesztivál keretein belül zajló kémiaverseny döntő eseményének. A verseny területi előválogató eseményén több, mint 150, technikus-jelölt tanuló vett részt egy 90 perces, interaktív teszt kitöltésében. Az előválogatóból 7 diák jutott el a végső válogatóig. Közülük ketten a Debreceni Szakképző Centrum Vegyipari Technikum, öten pedig a Budapesti Szakképzési Centrum Petrik Lajos Petrik Lajos Két Tanítási Nyelvű Technikum diákjai közül kerültek ki. 
    <br /><br />
    A Bálint Analitika laboratóriumaiban 2025. március 7-én és 8-án zajló versenyen a döntős diákoknak elméleti és gyakorlati tudásukról is számot kell adniuk. Gyakorlati feladatok során nedveskémiai „klasszikus” és műszeres analitikai eljárásokat alkalmazva kell mennyiségi analitikai vizsgálatot végezniük. 
    <br /><br />
    A Bálint Analitika Kft. büszke arra, hogy a verseny szervezői az idén is a mi laboratóriunkat jelölték ki e jeles versengés helyszínéül. A verseny részleteit az alábbi linken tekinthetik meg: 
    <br /><br />
    <a href="https://www.szakmasztar.hu/szakmak/vegyesz-technikus-altalanos-laborans-szakmairany" target="_blank" rel="noreferrer">https://www.szakmasztar.hu/szakmak/vegyesz-technikus-altalanos-laborans-szakmairany</a>.
    <br /><br />
    A Bálint Analitika laboratóriumában zajló eseményről készült fényképfelvételeket az alábbi galériában tekinthetik meg
    `,
    images: [
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_1.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_2.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_3.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_4.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_5.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_6.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_7.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_8.webp",
      },
      {
        url: "/assets/chemical/home/news/szakma_sztar_gallery/szakma_sztar_9.webp",
      },
    ],
  },
  {
    id: 15,
    title: "VI. Mentes Feszt Konferencia 2025",
    contentLink: "/kemiaianalitika/hirek/mentes_feszt_2025",
    picLink: "/assets/chemical/home/news/mentes-feszt_card.webp",
    heroImg: "/assets/chemical/home/news/mentes-feszt_banner_nagy.webp",
    date: "2025.03.12.",
    content: `
    Az Expedit Novum Kft. 2025. március 12-én tartotta VI. Mentes Feszt konferenciájat a CED székházának előadótermében, kb. 60 érdeklődő szakember előtt.
    <br />
    A hallgatósag soraiban mentes élelmiszereket előállító és forgalmazó cég munkatársai ültek.
    A konferencián a Bálnt Analitika Kft. is képviseltette magát a "mentes" élelmiszerek laboratóriumi vizsgálatának témájában. Az előadást Dr. Szigeti Tamás János stratégiai igazgató tartotta. Az előadás PDF verziója <a href="/assets/chemical/home/news/mentes_feszt_2025_gallery/Mentes_elelmiszertermekek-Mentes_Feszt-20250312_SzigetiT_compressed.pdf" target="_blank" rel="noreferrer">ITT</a> tekinthető meg.
   <br /><br />
    A Bálint Analitika Kft. a konferencia támogatása gyanánt felajánlotta, hogy a konferencián igazoltan megjelenő cégek számára <strong>2025. április 30-ig</strong> a társaság laboratóriumától megrendelt élelmiszer-és takarmány-vizsgálatok díjából <strong>10% kedvezményt</strong> biztosít.
   <br /><br />
    A konferencia programja az alábbi linken tekinthető meg:<br />
    <a href="https://mentesfeszt.hu" target="_blank" rel="noreferrer">Mentes Feszt 2025 program</a>
    `,
    images: [
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_1.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_2.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_3.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_4.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_5.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_6.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_7.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_8.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_9.webp",
      },
      {
        url: "/assets/chemical/home/news/mentes_feszt_2025_gallery/mentes_feszt_2025_10.webp",
      },
    ],
  },
  {
    id: 17,
    title: "Ismerje meg projektjeinket!",
    contentLink: "/kemiaianalitika/rolunk/projektek",
    picLink: "/assets/chemical/home/news/projektek_card.webp",
  },
];
